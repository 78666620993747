import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Important',
      },
      {
        color: 'primary',
        label: 'Merchant',
      },
      {
        color: 'warning',
        label: 'Channel',
      },
      {
        color: 'success',
        label: 'Milestone',
      },
      {
        color: 'info',
        label: 'ETC',
      },
    ],
    selectedCalendars: ['Important', 'Merchant', 'Channel', 'Milestone', 'ETC'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/calendar/events?start=${params.start}&end=${params.end}&types=${params.calendars.join(',')}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put('/calendar/event', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/calendar/event/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/calendar/event/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
